/*
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 10:08:13
 * @Description: 
 */
import moment from 'moment'


export function fmtDate(date){
  return date?moment(Number(date)).format('YYYY-MM-DD'):'';
}
export function fmtTime(date){
  return date?moment(date).format('YYYY-MM-DD HH:mm:ss'):'';
}